import { memo } from "react";
import { AppHeader } from "@lantern/react-components";
import { useUserContext } from "../../../auth/UserContextProvider";
import { useAppContext } from "./AppContextProvider";

const NavMenu = () => {
  const { user } = useUserContext();
  const { context } = useAppContext();
  const appsUri = context?.settings?.uriSettings;
  return (
    <AppHeader
      appName="People"
      currentApp="people"
      appsUri={appsUri}
      user={
        user
          ? {
              id: user.id,
              photoUri: user.person?.photos?.length ? user.person?.photos[0]?.uri : undefined
            }
          : undefined
      }
    />
  );
};

export default memo(NavMenu);
