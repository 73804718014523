/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
  Checkbox,
  TextField,
  Paper,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Chip,
  Input,
  Grid,
  ButtonGroup,
  Button,
  Collapse,
  alpha,
  Link
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import { PeopleSearchResponse } from "../../models/people";
import PeopleSearchResultList from "./PeopleSearchResultList";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { useNotifications } from "./NotificationProvider";
import { ExpandMoreRounded } from "@material-ui/icons";
import { SEARCH_PEOPLE } from "../../api/GraphQL/queries";
import PersonSelector from "./PersonSelector";

const useStyles = makeStyles((theme) => {
  const dgrey = theme.palette.dgrey.main;
  const lgrey = "#646464";
  const ltealText = theme.palette.text.lteal;
  const lteal = theme.palette.primary.light;
  const teal = theme.palette.primary.main;
  const dteal = theme.palette.primary.dark;
  const white = theme.palette.common.white;
  const black = theme.palette.common.black;
  const type = theme.palette.type;

  return {
    search: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2)
    },
    filters: {
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2)
    },
    personSelectorStyle: {
      paddingTop: "4px" // Adjust the value as needed
    },
    filterTitle: {
      color: type === "dark" ? white : lgrey,
      marginTop: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      fontWeight: 500
    },
    filter: {
      transition: theme.transitions.create(["transform"], {
        duration: theme.transitions.duration.short
      })
    },
    filterOpen: {
      transform: "rotate(-180deg)"
    },
    filterClosed: {
      transform: "rotate(0)"
    },
    contractTypeContainer: {
      marginTop: theme.spacing(1),
      "& .MuiButtonBase-root": {
        borderColor: lteal,
        borderWidth: "1px",
        borderStyle: "solid"
      }
    },
    contractTypeButton: {
      minWidth: theme.spacing(15),
      color: type === "dark" ? lteal : teal
    },
    filledButton: {
      minWidth: theme.spacing(15),
      color: type === "dark" ? dgrey : teal,
      backgroundColor: type === "dark" ? lteal : alpha(lteal, 0.15)
    },
    selectedItems: {
      marginRight: theme.spacing(0.5)
    },
    selectInput: {
      "&:before": {
        borderBottomColor: type === "dark" ? white : lgrey
      },
      "&:after": {
        borderBottomColor: type === "dark" ? white : lgrey
      }
    },
    hintText: {
      color: type === "dark" ? white : lgrey
    },
    filterButton: {
      marginTop: theme.spacing(1),
      fontWeight: 600, // semibold
      color: type === "dark" ? black : white,
      backgroundColor: type === "dark" ? lteal : dteal
    },
    resetButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      fontWeight: 600, // semibold
      color: type === "dark" ? lteal : teal,
      borderColor: lteal,
      borderWidth: "1px",
      borderStyle: "solid"
    },
    dataQualityLink: {
      color: type === "dark" ? ltealText : dteal,
      textDecoration: "underline",
      paddingRight: theme.spacing(4)
    }
  };
});

enum ContractTypeFilter {
  All = "All",
  EmployeesOnly = "EmployeesOnly",
  ContractorsOnly = "ContractorsOnly"
}

enum ContractTypeDocumentTitle {
  All = "All",
  Employees = "Employees",
  Contractors = "Contractors"
}

interface PeopleFilterState {
  query: string;
  contractType: string;
  studios: string[];
  departments: string[];
  managers: string[];
  cities: string[];
}

interface peopleFilteredListProps {
  updateDocumentTitle: (newTitle: string) => void;
}

const areEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) {
    return false;
  }
  const mismatch = a.filter((element) => !b.includes(element));
  return mismatch.length === 0;
};

const FilteredPeopleList = (props: peopleFilteredListProps) => {
  const classes = useStyles();
  const { setNotification } = useNotifications();
  const [knownOfficeLocations, setKnownOfficeLocations] = React.useState<string[]>([]);
  const [knownDepartments, setKnownDepartments] = React.useState<string[]>([]);
  const [knownCities, setKnownCities] = React.useState<string[]>([]);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [selectedOfficeLocations, setSelectedOfficeLocations] = React.useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = React.useState<string[]>([]);
  const [selectedManagers, setSelectedManagers] = React.useState<string[]>([]);
  const [selectedCities, setSelectedCities] = React.useState<string[]>([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const [filterText, setFilterText] = React.useState(queryParams.get("q") || "");

  const [filterState, setFilterState] = React.useState<PeopleFilterState>({
    query: queryParams.get("q") || "",
    contractType: queryParams.get("type") || "",
    studios: (queryParams.get("studios") || "").split(",").filter((r) => !!r),
    departments: (queryParams.get("departments") || "").split(",").filter((r) => !!r),
    managers: (queryParams.get("managers") || "").split(",").filter((r) => !!r),
    cities: (queryParams.get("cities") || "").split(",").filter((r) => !!r)
  });

  React.useEffect(() => {
    setFilterText(queryParams.get("q") || "");
    const newSelectedOfficeLocations = (queryParams.get("studios") || "").split(",").filter((r) => !!r);
    const newSelectedDepartments = (queryParams.get("departments") || "").split(",").filter((r) => !!r);
    const newSelectedManagers = (queryParams.get("managers") || "").split(",").filter((r) => !!r);
    const newSelectedCities = (queryParams.get("cities") || "").split(",").filter((r) => !!r);
    const newContractType = queryParams.get("type") || ContractTypeFilter.EmployeesOnly;
    setSelectedOfficeLocations(newSelectedOfficeLocations);
    setSelectedDepartments(newSelectedDepartments);
    setSelectedManagers(newSelectedManagers);
    setSelectedCities(newSelectedCities);
    setFilterState({
      query: queryParams.get("q") || "",
      contractType: newContractType,
      studios: newSelectedOfficeLocations,
      departments: newSelectedDepartments,
      managers: newSelectedManagers,
      cities: newSelectedCities
    });
    setDocumentTitleOnReload(newContractType);
  }, [queryParams]);

  const {
    loading,
    error,
    data: peopleSearchData
  } = useQuery<{ peopleSearch: PeopleSearchResponse }>(SEARCH_PEOPLE, {
    variables: {
      query: filterState.query,
      includeEmployees:
        filterState.contractType === ContractTypeFilter.EmployeesOnly || filterState.contractType === ContractTypeFilter.All,
      includeContractors:
        filterState.contractType === ContractTypeFilter.ContractorsOnly || filterState.contractType === ContractTypeFilter.All,
      studios: filterState.studios,
      departments: filterState.departments,
      managers: filterState.managers,
      cities: filterState.cities
    }
  });

  const setDocumentTitleOnReload = (filterType: string) => {
    switch (filterType) {
      case ContractTypeFilter.EmployeesOnly:
        props.updateDocumentTitle(ContractTypeDocumentTitle.Employees);
        break;
      case ContractTypeFilter.ContractorsOnly:
        props.updateDocumentTitle(ContractTypeDocumentTitle.Contractors);
        break;
      case ContractTypeFilter.All:
        props.updateDocumentTitle(ContractTypeDocumentTitle.All);
        break;
      default:
        props.updateDocumentTitle("");
        break;
    }
  };

  React.useEffect(() => {
    if (error?.message) {
      setNotification(error.message, "error");
    }
  }, [error, setNotification]);

  React.useEffect(() => {
    const officeLocationFacet = peopleSearchData?.peopleSearch.facets.find((f) => f.fieldName === "officeLocation");
    let officeLocations: string[] = [];
    if (officeLocationFacet) {
      officeLocations = officeLocationFacet.values.map((officeLocation) => officeLocation.value);
    }
    if (!areEqual(officeLocations, knownOfficeLocations)) {
      setKnownOfficeLocations(officeLocations);
    }

    const departmentsFacet = peopleSearchData?.peopleSearch.facets.find((f) => f.fieldName === "department");
    let departments: string[] = [];
    if (departmentsFacet) {
      departments = departmentsFacet.values.map((department) => department.value);
    }
    if (!areEqual(departments, knownDepartments)) {
      setKnownDepartments(departments);
    }

    const cityFacet = peopleSearchData?.peopleSearch.facets.find((f) => f.fieldName === "city");
    let cities: string[] = [];
    if (cityFacet) {
      cities = cityFacet.values.map((city) => city.value);
    }
    if (!areEqual(cities, knownCities)) {
      setKnownCities(cities);
    }
  }, [peopleSearchData, knownOfficeLocations, knownDepartments, knownCities]);

  const searchResults = peopleSearchData?.peopleSearch.results ?? [];

  const updateFilter = React.useRef(
    debounce((query: string, contractType: string, studios: string[], departments: string[], managers: string[], cities: string[]) => {
      const path = "/";
      const params: string[] = [];
      if (query) {
        params.push(`q=${encodeURIComponent(query)}`);
      }
      if (contractType.length && contractType !== ContractTypeFilter.EmployeesOnly) {
        params.push(`type=${contractType}`);
      }
      if (studios.length) {
        params.push(`studios=${studios.join(",")}`);
      }
      if (departments.length) {
        const encodedDepartments = departments.map((department) => encodeURIComponent(department));
        params.push(`departments=${encodedDepartments.join(",")}`);
      }
      if (managers.length) {
        const encodedManagers = managers.map((manager) => encodeURIComponent(manager));
        params.push(`managers=${encodedManagers.join(",")}`);
      }
      if (cities.length) {
        params.push(`cities=${cities.join(",")}`);
      }

      const route = params.length ? `${path}?${params.join("&")}` : path;

      navigate(route, { replace: true });
    }, 150)
  ).current;

  const handleFilterTextChange = (val: string) => {
    setFilterText(val);
    setSelectedDepartments([]);
    setSelectedOfficeLocations([]);
    setSelectedManagers([]);
    setSelectedCities([]);
    updateFilter(val, filterState.contractType, [], [], [], []);
  };

  const handleContractTypeChange = (contractType: string, filterTitle: string) => () => {
    setSelectedDepartments([]);
    setSelectedOfficeLocations([]);
    setSelectedManagers([]);
    setSelectedCities([]);
    updateFilter(filterState.query, contractType, [], [], [], []);
    props.updateDocumentTitle(filterTitle);
  };

  const handleStudioSelectionChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const newSelectedStudios = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
    setSelectedOfficeLocations(newSelectedStudios);
  };

  const removeSelectedStudio = (studio: string) => () => {
    const newSelectedStudio = selectedOfficeLocations.filter((selectedStudio) => selectedStudio !== studio);
    setSelectedOfficeLocations(newSelectedStudio);
  };

  const handleDepartmentSelectionChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const newSelectedDepartments = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
    setSelectedDepartments(newSelectedDepartments);
  };

  const removeSelectedDepartment = (department: string) => () => {
    const newSelectedDepartments = selectedDepartments.filter((selectedDepartment) => selectedDepartment !== department);
    setSelectedDepartments(newSelectedDepartments);
  };

  const handleCitySelectionChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    const newSelectedCities = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
    setSelectedCities(newSelectedCities);
  };

  const removeSelectedCity = (city: string) => () => {
    const newSelectedCities = selectedCities.filter((selectedCity) => selectedCity !== city);
    setSelectedCities(newSelectedCities);
  };

  const handleManagersChange = (value: string[]) => {
    setSelectedManagers(value);
  };

  const stopEventPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const isFilterApplied = () => {
    return filterState.departments.length > 0 || filterState.studios.length > 0;
  };

  const handleApplyFilters = () => {
    updateFilter(
      filterState.query,
      filterState.contractType,
      selectedOfficeLocations,
      selectedDepartments,
      selectedManagers,
      selectedCities
    );
  };

  const resetFilters = () => {
    updateFilter(filterState.query, "", [], [], [], []);
  };

  const numberOfMatches = searchResults.length || 0;

  return (
    <>
      <Paper className={classes.search}>
        <Grid container spacing={2}>
          <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
            <TextField
              label={<Typography className={classes.hintText}>Search for a name or a position...</Typography>}
              type="search"
              id="filterText"
              variant="outlined"
              fullWidth
              value={filterText}
              onChange={(searchString) => handleFilterTextChange(searchString.target.value)}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <ButtonGroup size="small" orientation="horizontal" className={classes.contractTypeContainer}>
              <Button
                disableElevation
                className={
                  filterState.contractType === ContractTypeFilter.EmployeesOnly ? classes.filledButton : classes.contractTypeButton
                }
                onClick={handleContractTypeChange(ContractTypeFilter.EmployeesOnly, ContractTypeDocumentTitle.Employees)}
                style={{ textTransform: "none" }}
              >
                Employees
              </Button>
              <Button
                disableElevation
                className={
                  filterState.contractType === ContractTypeFilter.ContractorsOnly ? classes.filledButton : classes.contractTypeButton
                }
                onClick={handleContractTypeChange(ContractTypeFilter.ContractorsOnly, ContractTypeDocumentTitle.Contractors)}
                style={{ textTransform: "none" }}
              >
                Contractors
              </Button>
              <Button
                disableElevation
                className={filterState.contractType === ContractTypeFilter.All ? classes.filledButton : classes.contractTypeButton}
                onClick={handleContractTypeChange(ContractTypeFilter.All, ContractTypeDocumentTitle.All)}
                style={{ textTransform: "none" }}
              >
                All
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Typography>
              <Link className={classes.dataQualityLink} component={RouterLink} to={`/data-quality`}>
                Profile data quality
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.filters}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography className={classes.hintText} gutterBottom>
              {loading || !searchResults ? "" : numberOfMatches + ` ${numberOfMatches !== 1 ? "people" : "person"} found`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={toggleFilter}
              disabled={isFilterApplied()}
              endIcon={<ExpandMoreRounded className={`${classes.filter} ${showFilter ? classes.filterOpen : classes.filterClosed}`} />}
            >
              Filter Results
            </Button>
          </Grid>
        </Grid>
        <Collapse in={showFilter || isFilterApplied()}>
          <Grid container spacing={2}>
            <Grid item md={3} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="studios-label-id">
                  <Typography className={classes.hintText}>Studios</Typography>
                </InputLabel>
                <Select
                  labelId="studios-label-id"
                  id="studios-select"
                  multiple
                  value={selectedOfficeLocations}
                  className={classes.selectInput}
                  renderValue={(selectedStudios: any) => (
                    <div>
                      {selectedStudios.map((studio: string) => (
                        <Chip
                          key={studio}
                          label={studio}
                          size="medium"
                          className={classes.selectedItems}
                          variant="outlined"
                          onDelete={removeSelectedStudio(studio)}
                          onMouseDown={stopEventPropagation}
                        />
                      ))}
                    </div>
                  )}
                  onChange={handleStudioSelectionChange}
                  input={<Input />}
                >
                  {knownOfficeLocations.map((studio) => {
                    const matchingFacet = peopleSearchData?.peopleSearch.facets
                      .find((f) => f.fieldName === "officeLocation")
                      ?.values.find((f) => f.value === studio);
                    return (
                      <MenuItem key={studio} value={studio}>
                        <Checkbox checked={selectedOfficeLocations.includes(studio)} />
                        <ListItemText primary={studio + (matchingFacet ? ` (${matchingFacet.count})` : "")} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="departmets-label-id">
                  <Typography className={classes.hintText}>Departments</Typography>
                </InputLabel>
                <Select
                  labelId="departmets-label-id"
                  id="departments-select"
                  multiple
                  value={selectedDepartments}
                  className={classes.selectInput}
                  renderValue={(selectedDepartments: any) => (
                    <div>
                      {selectedDepartments.map((department: string) => (
                        <Chip
                          key={department}
                          label={department}
                          size="medium"
                          className={classes.selectedItems}
                          variant="outlined"
                          onDelete={removeSelectedDepartment(department)}
                          onMouseDown={stopEventPropagation}
                        />
                      ))}
                    </div>
                  )}
                  onChange={handleDepartmentSelectionChange}
                  input={<Input />}
                >
                  {knownDepartments.map((department) => {
                    const matchingFacet = peopleSearchData?.peopleSearch.facets
                      .find((f) => f.fieldName === "department")
                      ?.values.find((f) => f.value === department);
                    return (
                      <MenuItem key={department} value={department}>
                        <Checkbox checked={selectedDepartments.includes(department)} />
                        <ListItemText primary={department + (matchingFacet ? ` (${matchingFacet.count})` : "")} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControl fullWidth>
                <div className={classes.personSelectorStyle} />
                {<PersonSelector value={selectedManagers} onChange={handleManagersChange} variant="standard" />}
              </FormControl>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="cities-label-id">
                  <Typography className={classes.hintText}>Cities</Typography>
                </InputLabel>
                <Select
                  labelId="cities-label-id"
                  id="cities-select"
                  multiple
                  value={selectedCities}
                  className={classes.selectInput}
                  renderValue={(selectedCities: any) => (
                    <div>
                      {selectedCities.map((city: string) => (
                        <Chip
                          key={city}
                          label={city}
                          size="medium"
                          className={classes.selectedItems}
                          variant="outlined"
                          onDelete={removeSelectedCity(city)}
                          onMouseDown={stopEventPropagation}
                        />
                      ))}
                    </div>
                  )}
                  onChange={handleCitySelectionChange}
                  input={<Input />}
                >
                  {knownCities.map((city) => {
                    const matchingFacet = peopleSearchData?.peopleSearch.facets
                      .find((f) => f.fieldName === "city")
                      ?.values.find((f) => f.value === city);
                    return (
                      <MenuItem key={city} value={city}>
                        <Checkbox checked={selectedCities.includes(city)} />
                        <ListItemText primary={city + (matchingFacet ? ` (${matchingFacet.count})` : "")} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <FormControl>
                <Button className={classes.resetButton} onClick={resetFilters} style={{ textTransform: "none" }} size="small">
                  Reset
                </Button>
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  className={classes.filterButton}
                  onClick={handleApplyFilters}
                  style={{ textTransform: "none" }}
                  size="small"
                >
                  Apply
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Paper>
      <PeopleSearchResultList loading={loading} peopleSearchResults={searchResults} />
    </>
  );
};

export default FilteredPeopleList;
