import * as React from "react";
import FilteredPeopleList from "./components/FilteredPeopleList";
import { Fade } from "@material-ui/core";
import DocumentTitle from "./components/DocumentTitle";

const DashboardPage: React.FunctionComponent = () => {
  const [documentTitle, setDocumentTitle] = React.useState<string>("");

  return (
    <>
      <DocumentTitle pageTitle={documentTitle} />
      <Fade in>
        <FilteredPeopleList updateDocumentTitle={setDocumentTitle} />
      </Fade>
    </>
  );
};
export default DashboardPage;
