import * as React from "react";
import { TableRow, TableCell, Typography, makeStyles, Collapse, IconButton, Grid } from "@material-ui/core";
import { FieldDataQuality, PersonProfilesDataQualityReport } from "../../models/dataQuality";
import { PersonAvatar } from "@lantern/react-components";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DataQualityScoreProgressBar from "../DataQualityScoreProgressBar";
import DataQualityScoreIcon from "./DataQualityScoreIcon";
import DataQualityTableRowDetails from "./DataQualityTableRowDetails";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  centered: {
    justifyItems: "center",
    alignItems: "center"
  },
  left: {
    justifyItems: "left",
    alignItems: "left"
  },
  right: {
    textAlign: "right",
    verticalAlign: "middle"
  },
  aligned: {
    display: "inline-flex",
    "& > *": {
      marginRight: theme.spacing(1)
    }
  },
  expansionCell: {
    paddingBottom: 0,
    paddingTop: 0
  },
  wrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center"
  }
}));

const DataQualityTableRow: React.FunctionComponent<{ report?: PersonProfilesDataQualityReport }> = (props) => {
  const classes = useStyles();
  const { report } = props;
  const person = report?.person;
  const [isOpen, setIsOpen] = React.useState(false);

  const getFieldByName = (name: string): FieldDataQuality | undefined => {
    return report?.fields.find((f) => f.fieldName.toLowerCase() === name.toLowerCase());
  };

  const bioFieldQuality = getFieldByName("bio");
  const devFactoWorkDescriptionFieldQuality = getFieldByName("devFactoWorkDescription");
  const skillsFieldQuality = getFieldByName("skills");
  const educationFieldQuality = getFieldByName("education");
  const workExperienceFieldQuality = getFieldByName("workExperience");
  const projectsFieldQuality = getFieldByName("projects");

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            {person && (
              <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            {person ? (
              <PersonAvatar link={`/people/${person.id}`} photoUri={person.photos.length ? person.photos[0].uri : undefined} />
            ) : (
              <Skeleton variant="circle" width={72} height={72} />
            )}
          </span>
        </TableCell>
        <TableCell>
          <span className={classes.wrapper}>
            <Typography>{person ? person.fullName : <Skeleton variant="text" width={140} />}</Typography>
            {report && <DataQualityScoreProgressBar score={report.overallScore} />}
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.right)}>
            <Typography>{report ? report.overallScore : <Skeleton variant="text" width={20} />}</Typography>
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            <DataQualityScoreIcon score={bioFieldQuality?.score} violations={bioFieldQuality?.violations} />
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            <DataQualityScoreIcon
              score={devFactoWorkDescriptionFieldQuality?.score}
              violations={devFactoWorkDescriptionFieldQuality?.violations}
            />
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            <DataQualityScoreIcon score={skillsFieldQuality?.score} violations={skillsFieldQuality?.violations} />
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            <DataQualityScoreIcon score={workExperienceFieldQuality?.score} violations={workExperienceFieldQuality?.violations} />
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.centered)}>
            <DataQualityScoreIcon score={educationFieldQuality?.score} violations={educationFieldQuality?.violations} />
          </span>
        </TableCell>
        <TableCell>
          <span className={clsx(classes.wrapper, classes.left)}>
            <span className={classes.aligned}>
              <DataQualityScoreIcon
                score={projectsFieldQuality?.score}
                violations={
                  projectsFieldQuality && projectsFieldQuality.score < 100 ? [{ description: "Expand row to see details" }] : undefined
                }
              />{" "}
              {projectsFieldQuality ? (
                `${projectsFieldQuality.children.filter((p) => p.score === 100).length}/${projectsFieldQuality.children.length}`
              ) : (
                <Skeleton variant="text" width={30} />
              )}
            </span>
          </span>
        </TableCell>
      </TableRow>
      {report && (
        <TableRow>
          <TableCell className={classes.expansionCell} />
          <TableCell className={classes.expansionCell} colSpan={9}>
            <Collapse in={isOpen}>
              {isOpen && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DataQualityTableRowDetails personDataQualityReport={report} />
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default DataQualityTableRow;
