import { memo } from "react";
import { Chip, makeStyles, Tooltip, Typography } from "@material-ui/core";
import HeartIcon from "@material-ui/icons/Favorite";
import { PersonSkill, BeaconItemCategory } from "../../models/people";
import { useAppContext } from "./layout/AppContextProvider";

const useStyles = makeStyles((theme) => ({
  chips: {
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}));

const GroupedPersonSkillList = (props: { skills: PersonSkill[] }) => {
  const classes = useStyles();
  const { context } = useAppContext();
  const { skills } = props;

  const sortedSkills = [...skills].sort((a, b) => {
    return a.beaconItem.name < b.beaconItem.name ? -1 : a.beaconItem.name > b.beaconItem.name ? 1 : 0;
  });

  const groupedItems = new Map<BeaconItemCategory, PersonSkill[]>();
  for (const skill of sortedSkills) {
    const key = skill.beaconItem.category || BeaconItemCategory.None;
    const list = (groupedItems.get(key) || []).concat(skill);
    groupedItems.set(key, list);
  }

  const groups = [
    { title: "Methodologies", category: BeaconItemCategory.Methodology },
    { title: "Techniques", category: BeaconItemCategory.Technique },
    { title: "Tools", category: BeaconItemCategory.Tool },
    { title: "Technologies", category: BeaconItemCategory.Technology },
    { title: "Other", category: BeaconItemCategory.None }
  ];

  return (
    <div>
      {groups.map((g) => {
        return (
          (groupedItems.get(g.category) || []).length > 0 && (
            <div key={g.category}>
              <Typography variant="caption" color="textSecondary">
                {g.title}
              </Typography>
              <div className={classes.chips}>
                {(groupedItems.get(g.category) || []).map((item) => {
                  return (
                    <Tooltip
                      key={item.beaconItem.id}
                      title={item.beaconItem.previewDescription || "<no description available>"}
                      aria-label="add"
                    >
                      <Chip
                        component="a"
                        target="_blank"
                        href={`${context?.settings.uriSettings.beaconApp}/items/${item.beaconItem.id}`}
                        clickable
                        icon={item.isLoved ? <HeartIcon fontSize="small" style={{ color: "#f44336" }} /> : undefined}
                        label={item.beaconItem.name}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default memo(GroupedPersonSkillList);
