import { memo } from "react";
import { Chip, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { BeaconItemCategory, BeaconItem } from "../../models/people";
import { useAppContext } from "./layout/AppContextProvider";

const useStyles = makeStyles((theme) => ({
  chips: {
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  text: {
    color: theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.common.black
  }
}));

const GroupedBeaconItemList = (props: { items: BeaconItem[] }) => {
  const classes = useStyles();
  const { context } = useAppContext();
  const { items } = props;

  const sortedItems = [...items].sort((a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  });

  const groupedItems = new Map<BeaconItemCategory, BeaconItem[]>();
  for (const item of sortedItems) {
    const key = item.category || BeaconItemCategory.None;
    const list = (groupedItems.get(key) || []).concat(item);
    groupedItems.set(key, list);
  }

  const groups = [
    { title: "Methodologies", category: BeaconItemCategory.Methodology },
    { title: "Techniques", category: BeaconItemCategory.Technique },
    { title: "Tools", category: BeaconItemCategory.Tool },
    { title: "Technologies", category: BeaconItemCategory.Technology },
    { title: "Other", category: BeaconItemCategory.None }
  ];

  return (
    <div>
      {groups.map((g) => {
        return (
          (groupedItems.get(g.category) || []).length > 0 && (
            <div key={g.category}>
              <Typography variant="caption" className={classes.text}>
                {g.title}
              </Typography>
              <div className={classes.chips}>
                {(groupedItems.get(g.category) || []).map((item) => {
                  return (
                    <Tooltip key={item.id} title={item.previewDescription || "<no description available>"} aria-label="add">
                      <Chip
                        size="small"
                        component="a"
                        target="_blank"
                        href={`${context?.settings.uriSettings.beaconApp}/items/${item.id}`}
                        clickable
                        label={item.name}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default memo(GroupedBeaconItemList);
